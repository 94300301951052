"use client";

import React, { createContext, useContext, useState, useEffect } from "react";
import { createClient } from "@/lib/supabase/client";
import { User } from "@supabase/supabase-js";
type UserContextType = {
  user: User | null;
  isLoading: boolean;
};
const UserContext = createContext<UserContextType>({
  user: null,
  isLoading: true
});
export const useUser = () => useContext(UserContext);
export const UserProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const supabase = createClient();
  useEffect(() => {
    // Fetch user on initial load
    const getUser = async () => {
      const {
        data: {
          user
        }
      } = await supabase.auth.getUser();
      setUser(user);
      setIsLoading(false);
    };
    getUser();

    // Listen for auth state changes
    const {
      data: authListener
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
    });

    // Cleanup on unmount
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [supabase]);
  return <UserContext.Provider value={{
    user,
    isLoading
  }} data-sentry-element="unknown" data-sentry-component="UserProvider" data-sentry-source-file="user.tsx">
      {children}
    </UserContext.Provider>;
};