import { createClient } from "@/lib/supabase/client"

export const getAccessToken = async () => {
  const supabase = createClient()
  const { data, error } = await supabase.auth.getSession()

  if (error) {
    console.log(error)
  } else {
    return data.session?.access_token
  }
}
