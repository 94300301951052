import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.12_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.12_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.12_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Raleway\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"600\"],\"display\":\"swap\",\"variable\":\"--font-raleway\"}],\"variableName\":\"raleway\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.12_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\"],\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.12_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Lato\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"900\"],\"variable\":\"--font-lato\"}],\"variableName\":\"lato\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/nextjs-toploader@3.6.15_next@14.2.12_react-dom@18.3.1_react@18.3.1/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/vercel/path0/src/app/ApolloWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/slick-carousel@1.8.1_jquery@3.7.1/node_modules/slick-carousel/slick/slick.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/slick-carousel@1.8.1_jquery@3.7.1/node_modules/slick-carousel/slick/slick-theme.css");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/vercel/path0/src/contexts/user.tsx");
